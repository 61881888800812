import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { options } from "../components/parser"
import parse from "html-react-parser"
import {
  TextCard,
  SoliWhite,
  SoliCard,
  BigText,
  Anchor,
} from "../components/styles"
import { SitFloat } from "../components/headerlinks"

const RightSide = ({
  data: {
    id,
    html,
    frontmatter: { title, bild, bildtext },
  },
}) => (
  <SoliCard id={id}>
    <SoliWhite>
      {title}
      <SitFloat />
    </SoliWhite>

    <BigText>{parse(html, options)}</BigText>
  </SoliCard>
)

const Impressum = ({ data }) => {
  return (
    <Layout
      /* leftside={<LeftSide data={Impressumlinks()}/>} */
      rightside={<RightSide data={data.Impressumrechts} />}
    >
      <TextCard>
        <Anchor id={data.Impressum.frontmatter.location} />
        {parse(data.Impressum.html, options)}
        <Anchor id={data.Datenschutz.frontmatter.location} />
        {parse(data.Datenschutz.html, options)}
      </TextCard>
    </Layout>
  )
}

export default Impressum

export const query = graphql`
  query ImpressumQuery {
    Impressum: markdownRemark(frontmatter: { location: { eq: "Impressum" } }) {
      id
      frontmatter {
        location
      }
      html
    }
    Datenschutz: markdownRemark(
      frontmatter: { location: { eq: "Impressum/#Datenschutz" } }
    ) {
      id
      frontmatter {
        location
      }
      html
    }
    Impressumrechts: markdownRemark(
      frontmatter: { location: { eq: "Impressumrechts" } }
    ) {
      id
      frontmatter {
        bild
        bildtext
        location
        online
        title
      }
      html
    }
  }
`
